export function SimpliqThemeDropMenuDirective () {
  return {
    restrict: 'A',
    scope: {},
    link (scope, element) {
      element.click(e => {
        e.preventDefault();
    
        element.parent().find('ul').first().slideToggle();
      });
    }
  }
}
