import 'angular';

import { SimpliqThemeDropMenuDirective }       from './simpliq-theme.drop-menu.directive';
import { SimpliqThemeMainMenuToggleDirective } from './simpliq-theme.main-menu-toggle.directive';
import { SimpliqThemeWidthFunctionsDirective } from './simpliq-theme.width-functions.directive';

angular.module('simpliq-theme', [])
  .directive('stDropMenu', SimpliqThemeDropMenuDirective)
  .directive('stMainMenuToggle', SimpliqThemeMainMenuToggleDirective)
  .directive('stWidthFunctions', SimpliqThemeWidthFunctionsDirective);
