import 'angular';

import widthFunctions from './widthFunctions';

export function SimpliqThemeWidthFunctionsDirective ($timeout, $window) {
  return {
    restrict: 'A',
    scope: {},
    link (scope, element) {
      $timeout(() => {
        widthFunctions();
      });

      angular.element($window).bind('resize', widthFunctions);
    }
  }
}
